import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    decrementBundleSelectionQty,
    decrementItemSelectionQty,
    incrementBundleSelectionQty,
    incrementItemSelectionQty,
} from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';
import useProductsQuery from 'api/queries/useProductsQuery';
import { SELECTIONS_QUERY_KEY } from 'api/queries/useSelectionsQuery';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';
import useProductSource from 'hooks/useProductSource';
import { trackEvent } from 'utils/analyticsUtils';
import logError from 'utils/errorUtils';
import { getOrderSessionStorage, setOrderSessionStorage } from 'utils/orderSessionStorageUtils';

/**
 * Enum for mutation operations.
 * @readonly
 * @enum {string}
 */
const OperationTypes = Object.freeze({
    DECREMENT: 'decrement',
    INCREMENT: 'increment',
});

/**
 * Optimistic updates for mutating a product's selection quantity.
 * @param {string} productID - The ID of the product/selection to mutate.
 * @returns {Object} - Functions for mutating a product's selection quantites.
 */
function useUpdateProductQty({
    aisleID,
    carouselHorizontalScroll,
    carouselID,
    carouselName,
    cart,
    customAisle,
    dsSource,
    isCarouselModalCard = false,
    isPerk = false,
    plusMemberPriceEligible,
    pointPrice,
    productID,
    productName,
    searchAutocomplete,
    searchRecommendation,
    upSellsSource = null,
}) {
    const {
        addCounter,
        alerts,
        searchQuery,
        searchResultsLength,
        setAddCounter,
        setAlerts,
        setProductAdded,
        setProductAddedID,
    } = useGlobalStore();

    const queryClient = useQueryClient();
    const { data: account = {}, isLoading: accountIsLoading } = useAccountQuery();
    const { data: products = {}, isLoading: productsIsLoading } = useProductsQuery();
    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();
    const source = useProductSource({
        aisleID,
        carouselID,
        carouselName,
        customAisle,
        searchAutocomplete,
        searchRecommendation,
        upSellsSource,
    });

    const plusMembershipPricing = inPlusMembershipTreatment && plusMemberPriceEligible && plusMembershipEligibleOrder;

    const isLoading = accountIsLoading || productsIsLoading;

    const queryKey = [SELECTIONS_QUERY_KEY, account?.nextOrder.chargeID];

    const chargeID = account?.nextOrder?.chargeID;
    const productIsBundle = products?.bundles?.find((bundle) => bundle.bundleID === productID);

    // NOTE: the isPerk property identifies a perk selection, but when mutating, the BE expects asPerk instead.
    const sendParams = {};

    sendParams[productIsBundle ? 'sellableBundleID' : 'sellableItemID'] = productID;

    if (!cart) {
        sendParams.id = productID;
        sendParams.qty = 0;
        sendParams.aisleID = aisleID;
        sendParams.carouselID = carouselID;
        sendParams.searchTerm = window.location.pathname.includes('search') || searchAutocomplete ? searchQuery : '';
        sendParams.device = window.isNativeApp ? 'app' : 'web';
        sendParams.sourcePage = source;
        sendParams.asPerk = isPerk;
        sendParams.dsSource = dsSource;
    }

    /**
     * Build an updated list of selections, including the mutated selection.
     *   - Decrementing a selection to zero will remove the selection from the list.
     *   - Incrementing a non-existiant selection will add a new selection to the list.
     * @param {OperationTypes} operation - The operation to perform on the item's qty.
     * @param {Selection[]} selections - The relevant list of selections.
     */
    const updateSelections = ({ operation, selections }) => {
        let updatedSelections = [];
        let existingSelection = false;

        [...selections].forEach((selection) => {
            const selectionFound = selection.id === productID && !!selection.isPerk === isPerk;

            // If decrementing an existing selection with a quantity <= 1,
            // remove it from [don't add it to] the updated list of selections.
            if (selectionFound && operation === 'decrement' && selection.qty <= 1 && !!selection.isPerk === isPerk) {
                existingSelection = true;
                return;
            }

            // If decrementing an existing selection, decrement its quantity and
            // add it to the updated list of selections.
            if (selectionFound && operation === 'decrement' && !!selection.isPerk === isPerk) {
                updatedSelections = [...updatedSelections, { ...selection, qty: selection.qty - 1, pointPrice }];
                existingSelection = true;
                return;
            }

            // If incrementing an existing selection, increment its quantity and
            // add it to the updated list of selections.
            if (selectionFound && operation === 'increment' && !!selection.isPerk === isPerk) {
                if (!cart) {
                    sendParams.qty = selection.qty + 1;
                }
                updatedSelections = [...updatedSelections, { ...selection, qty: selection.qty + 1, pointPrice }];
                existingSelection = true;
                return;
            }

            // If the iterator is not the target selection, do not mutate it before
            // adding it to the list of updated selections.
            updatedSelections = [...updatedSelections, selection];
        });

        // If the selection was not found in the existing list of selections,
        // it needs to be added as  a new selection. Get its data from products
        // and set its quantity to 1 before adding it to the list of updated selections.
        if (!existingSelection) {
            if (!cart) {
                sendParams.qty = 1;
            }
            const newSelection = {
                ...(productIsBundle ? products.bundles : products.items).find((product) => product.id === productID),
            };
            updatedSelections = [...updatedSelections, { ...newSelection, qty: 1, isPerk, pointPrice }];
        }

        return updatedSelections;
    };

    /**
     * Determines whether to update the `selected` or `selectedBundles` key.
     * @param {OperationTypes} operation - The operation to perform on the item's qty.
     * @param {Selection[]} selections - The relevant list of selections.
     */
    const getUpdatedSelections = ({ operation, selections }) => ({
        ...selections,
        ...(!productIsBundle && {
            selected: updateSelections({
                selections: selections.selected,
                operation,
            }),
        }),
        ...(productIsBundle && {
            selectedBundles: updateSelections({
                selections: selections.selectedBundles,
                operation,
            }),
        }),
    });

    /**
     * Determines the appropriate API endpoint to call based on the passed in
     * operation and product type (item, bundle).
     * @param {OperationTypes} operation - The operation to perform on the item's qty.
     */
    const getMutationFn = ({ operation }) => {
        if (!productIsBundle && operation === OperationTypes.INCREMENT) {
            return incrementItemSelectionQty({ chargeID, sendParams });
        }
        if (!productIsBundle && operation === OperationTypes.DECREMENT) {
            return decrementItemSelectionQty({ chargeID, sendParams });
        }
        if (productIsBundle && operation === OperationTypes.INCREMENT) {
            return incrementBundleSelectionQty({ chargeID, sendParams });
        }
        if (productIsBundle && operation === OperationTypes.DECREMENT) {
            return decrementBundleSelectionQty({ chargeID, sendParams });
        }
        return null;
    };

    /**
     * Tracks an update quantity analytics event.
     *
     * @param {Object} location - A Location object.
     * @param {OperationTypes} operationType - The operation type.
     */
    const trackUpdateQtyEvent = ({ location, operationType }) => {
        const eventTitle = isPerk ? 'loyalty item quantity change' : 'item quantity change';
        const { selected = [], selectedBundles = [] } = queryClient.getQueryData(queryKey);
        const isBundle = !!sendParams.sellableBundleID;
        const item = isBundle
            ? selectedBundles.find(({ id }) => id === sendParams.sellableBundleID)
            : selected.find(({ id }) => id === sendParams.sellableItemID);

        trackEvent(eventTitle, {
            type: operationType,
            aisle_id: aisleID,
            carousel_name: carouselName,
            chargeID,
            has_membership_pricing: plusMembershipPricing,
            horizontal_scroll: carouselHorizontalScroll,
            isCarouselModalCard,
            location,
            modal: isCarouselModalCard,
            newQuantity: item?.qty || 0,
            pathname: window.location.pathname,
            product_name: productName,
            productID,
            search_term: searchQuery,
            search_results_length: searchResultsLength,
        });
    };

    /**
     * TanStack Query hook to optimistically update the cache directly.
     * Reference: https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
     */
    const productQtyMutation = useMutation({
        // API call:
        mutationFn: getMutationFn,

        // When mutate is called:
        onMutate: async (args) => {
            // Cancel any outgoing refetches, such that
            // they don't overwrite our optimistic update.
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous selections.
            const previousSelections = queryClient.getQueryData(queryKey);

            // _Optimistically_ update the selections.
            queryClient.setQueryData(queryKey, (selections) =>
                getUpdatedSelections({
                    operation: args.operation,
                    selections,
                    sendParams,
                })
            );

            // Hide the curated cart banner upon any mutation to a product qty.
            const { hideCuratedCartBanner } = getOrderSessionStorage();
            if (!hideCuratedCartBanner) {
                setOrderSessionStorage({ hideCuratedCartBanner: true });
            }

            // Return a context object with the snapshotted previous selections.
            return { previousSelections };
        },

        // If the mutation fails:
        onError: (err, _, context) => {
            // Use the context returned from onMutate to roll back.
            queryClient.setQueryData(queryKey, context.previousSelections);
            logError(err);
            console.error(err);
        },

        // After success:
        onSuccess: (_, variables) => {
            const { location, operation } = variables;
            trackUpdateQtyEvent({ location, operationType: operation });
        },

        // After error or success:
        onSettled: (data) => {
            // Refetch selections.
            queryClient.invalidateQueries({ queryKey });
            if (data?.msg === 'Max point spend exceeded') {
                setAlerts([...alerts, 'maxLoyaltyPointsSpendAlert']);
            }
        },
    });

    /**
     * Decrement the product's selection quantity.
     * Removes the selection if its mutated value is zero.
     *
     * @param {string} location - a string representing where in the UI the interaction took place (eg, "cart", or "product-card")
     *
     * @example decrementProductQty("cart");
     */
    const decrementProductQty = (location) =>
        productQtyMutation.mutate({
            chargeID,
            location,
            operation: OperationTypes.DECREMENT,
            sendParams,
        });

    /**
     * Increment the product's selection quantity.
     * Adds a new selection if one does not already exist.
     *
     * @param {string} location - a string representing where in the UI the interaction took place (eg, "cart", or "product-card")
     *
     * @example incrementProductQty("cart");
     */
    const incrementProductQty = (location) => {
        productQtyMutation.mutate({
            chargeID,
            location,
            operation: OperationTypes.INCREMENT,
            sendParams,
        });
        setAddCounter(addCounter + 1);
        setProductAdded(true);
        setProductAddedID(productID);
    };

    return { decrementProductQty, incrementProductQty, isLoading };
}

export default useUpdateProductQty;
