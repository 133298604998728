import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router';

import { usePromoCodeContext } from 'contexts/PromoCodeContext';
import { STICKY_SHOP_NAVIGATION_STYLES } from 'hooks/useStickyShopNavigation';
import useTailwindTheme from 'hooks/useTailwindTheme';

export default function AccountCreationLayout() {
    const { renderSignupNanoBanner } = usePromoCodeContext();

    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const onMembershipStep = window.location.pathname === '/join/membership';

    return (
        <>
            <main
                className={clsx('mx-auto flex max-w-screen-md flex-col px-25', {
                    [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNanobanner]: renderSignupNanoBanner,
                    [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNoNanobanner]: !renderSignupNanoBanner,
                    'py-0': onMembershipStep,
                    'py-25': !onMembershipStep,
                })}
            >
                <Outlet />
            </main>
            {!mobile && onMembershipStep && (
                <img
                    src="https://img-cdn.misfitsmarket.com/boysenberry/plusmembership/blueberries-desktop.png"
                    alt=""
                    className="fixed bottom-0 z-[-1] w-fit"
                />
            )}
        </>
    );
}
