import { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Outlet, useMatch, useNavigate } from 'react-router';

import { usePromoCodeContext } from 'contexts/PromoCodeContext';
import Progress from 'honeydew/Progress';
import { STICKY_SHOP_NAVIGATION_STYLES } from 'hooks/useStickyShopNavigation';
import { trackExistingUserRedirect } from 'utils/analyticsUtils';
import { getQuizStepsOrdered, setSignupLocalStorage } from 'utils/signupUtils';

export default function SignupQuizLayout() {
    const { renderSignupNanoBanner } = usePromoCodeContext();

    const navigate = useNavigate();
    const pathMatch = useMatch('/join/quiz/:quizStep');
    const [showBackArrow, setShowBackArrow] = useState(true);
    const quizSteps = getQuizStepsOrdered();
    const quizStepIndex = quizSteps.indexOf(pathMatch.params.quizStep);
    const quizProgress = ((quizStepIndex + 1) / quizSteps.length) * 100;

    const handleBackClick = () => {
        if (quizStepIndex === 0) {
            setSignupLocalStorage({ path: '/join' });
            navigate('/join');
        } else {
            navigate(quizSteps[quizStepIndex - 1]);
        }
    };

    const handleContinueClick = () => {
        const nextQuizPath = `/join/quiz/${quizSteps[quizStepIndex + 1]}`;
        setSignupLocalStorage({ path: nextQuizPath });
        navigate(nextQuizPath);
    };

    useEffect(() => {
        if (!pathMatch) {
            trackExistingUserRedirect({ destination: '/join' });
            navigate('/join');
        }
    }, []);

    if (!pathMatch) return false;

    return (
        <main>
            <div
                className={clsx('mx-auto flex w-full max-w-[600px] items-center gap-10 px-25', {
                    [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNanobanner]: renderSignupNanoBanner,
                    [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNoNanobanner]: !renderSignupNanoBanner,
                })}
            >
                <div className="h-[24px]">
                    {showBackArrow && (
                        <button onClick={handleBackClick} type="button">
                            <ArrowLeftIcon className="h-[24px] w-[24px]" />
                            <span className="sr-only">Go back</span>
                        </button>
                    )}
                </div>
                <Progress progress={quizProgress} width="full" />
            </div>
            <Outlet context={{ handleContinueClick, setShowBackArrow }} />
        </main>
    );
}
