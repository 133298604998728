import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import useTailwindTheme from 'hooks/useTailwindTheme';

export const STICKY_SHOP_NAVIGATION_STYLES = {
    sticky: 'sticky top-0 duration-200',
    notSticky: 'sticky duration-200',
    desktopSticky: 'sticky top-80 z-[1000] py-10 !mx-[-30px] pr-[30px]',
    paddingTopNanobanner: 'pt-[220px]',
    paddingTopNoNanobanner: 'pt-[160px]',
};

export default function useStickyShopNavigation() {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const previousY = useRef(0);
    const blocking = useRef(false);

    const [sticky, setSticky] = useState(false);

    function updateStickyStatus() {
        const currentY = window.scrollY;
        setSticky(currentY < previousY.current);
        previousY.current = currentY;
        blocking.current = false;
    }

    function onScroll() {
        if (!blocking.current) {
            blocking.current = true;
            window.requestAnimationFrame(updateStickyStatus);
        }
    }

    useEffect(() => {
        if (mobile) {
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            if (mobile) {
                window.removeEventListener('scroll', onScroll);
            }
        };
    }, [mobile]);

    return {
        sticky,
    };
}
