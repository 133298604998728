import { Experiment } from '@amplitude/experiment-js-client';

import { getIsProduction } from 'utils';
import { logMessage } from 'utils/sentryUtils';

/**
 * Deployment keys associated to projects in Amplitude
 * See https://amplitude.com/docs/experiment/data-model#deployments
 */
const DEPLOYMENT_KEYS = {
    misfits: 'client-AF5oGy0fCOyIYEInfjoqAgTujyLR9QP7',
    misfitsDevSandbox: 'client-Oov8Cu8vU0RhtaXqKBcz51NPfADGuCIr',
};

/**
 * Gets the deployment key for the current environment
 * @returns {string} the deployment key
 */
function getDeploymentKey() {
    const isProduction = getIsProduction();

    if (isProduction) {
        return DEPLOYMENT_KEYS.misfits;
    }

    return DEPLOYMENT_KEYS.misfitsDevSandbox;
}

/**
 * Initializes Amplitude's experiment client and returns it
 * See https://amplitude.com/docs/sdks/experiment-sdks/experiment-javascript#initialize-the-sdk
 * @returns Amplitude's experiment client
 */
async function initializeExperimentClient() {
    const deploymentKey = getDeploymentKey();

    let experimentClient;

    try {
        experimentClient = Experiment.initializeWithAmplitudeAnalytics(deploymentKey);
        await experimentClient.start();
    } catch (error) {
        // Swallow these on web for now. See CUS-5664.
        if (window.isNativeApp) {
            logMessage('Error initializing Amplitude Experiment client', { level: 'warning', extra: { error } });
        }
    }

    return experimentClient;
}

export { DEPLOYMENT_KEYS, initializeExperimentClient, getDeploymentKey };
