import { createContext, useEffect, useMemo, useRef, useState } from 'react';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';
import { getBrandDomain } from 'utils';

const GlobalContext = createContext(null);

function GlobalProvider({ children }) {
    const brandDomain = getBrandDomain();
    const urlParams = new URLSearchParams(window.location.search);
    const openCart = urlParams.get('cart') || false;
    const [cartOpen, setCartOpen] = useState((window.isNativeApp && openCart === 'true') ?? false);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [searchResultsLength, setSearchResultsLength] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [productAdded, setProductAddedState] = useState(false);
    const [productAddedID, setProductAddedID] = useState(null);
    const [seeAllCarouselId, setSeeAllCarouselId] = useState(null);
    const [scrollToSeeAllProductId, setScrollToSeeAllProductId] = useState(null);
    const [addCounter, setAddCounter] = useState(0);
    const [theme, setTheme] = useState(brandDomain ?? MISFITS_THEME_DOMAIN);
    const [alerts, setAlerts] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(() => {
        const storedFilters = localStorage.getItem('APPLIED_FILTERS');
        return storedFilters ? JSON.parse(storedFilters) : [];
    });
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [currentAisleId, setCurrentAisleId] = useState(null);

    const productAddedTimer = useRef();

    useEffect(() => {
        localStorage.setItem('APPLIED_FILTERS', JSON.stringify(appliedFilters));
    }, [appliedFilters]);

    const setProductAdded = () => {
        setProductAddedState(true);

        if (productAddedTimer.current) {
            clearTimeout(productAddedTimer.current);
        }

        productAddedTimer.current = setTimeout(() => {
            setProductAddedState(false);
        }, 3000);
    };

    const contextValue = useMemo(
        () => ({
            autocompleteOpen,
            cartOpen,
            filtersOpen,
            isImperfectTheme: theme === IMPERFECT_THEME_DOMAIN,
            isMisfitsTheme: theme === MISFITS_THEME_DOMAIN,
            productAdded,
            productAddedID,
            searchResultsLength,
            searchQuery,
            setSearchQuery,
            setAutocompleteOpen,
            setCartOpen,
            setFiltersOpen,
            setSearchResultsLength,
            setProductAdded,
            setProductAddedID,
            setTheme,
            theme,
            alerts,
            setAlerts,
            addCounter,
            setAddCounter,
            seeAllCarouselId,
            setSeeAllCarouselId,
            scrollToSeeAllProductId,
            setScrollToSeeAllProductId,
            appliedFilters,
            setAppliedFilters,
            searchOpen,
            setSearchOpen,
            currentAisleId,
            setCurrentAisleId,
        }),
        [
            addCounter,
            alerts,
            autocompleteOpen,
            cartOpen,
            currentAisleId,
            filtersOpen,
            productAdded,
            searchQuery,
            searchResultsLength,
            theme,
            seeAllCarouselId,
            scrollToSeeAllProductId,
            appliedFilters,
            searchOpen,
        ]
    );

    return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}

export { GlobalContext, GlobalProvider };
