import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { getIsProduction } from 'utils';
import { logMessage } from 'utils/sentryUtils';

import { initializeExperimentClient } from './utils';

const AmplitudeExperimentContext = React.createContext();

const SDK_TIMEOUT_WAIT = 10000;

const isProduction = getIsProduction();

/**
 * This initializes Amplitude's experiment SDK, providing Amplitude Feature Flags to components within the provider
 * See https://amplitude.com/docs/sdks/experiment-sdks/experiment-javascript
 * @param {React.ReactNode} children
 * @returns {React.ReactElement} AmplitudeExperimentContext.Provider
 */
function AmplitudeExperimentProvider({ children }) {
    const mounted = useRef(false);

    const [experimentClient, setExperiementClient] = useState(null);
    const [experimentClientIsReady, setExperimentClientIsReady] = useState(false);
    const [experimentClientIsTimedOut, setIsExperimentClientTimedOut] = useState(false);

    useEffect(() => {
        let timeout;

        async function initializeExperimentClientOnMount() {
            const initializedExperimentClient = await initializeExperimentClient();

            if (initializedExperimentClient) {
                setExperiementClient(initializedExperimentClient);
                setExperimentClientIsReady(true);
                clearTimeout(timeout);
            }
        }

        if (!mounted.current) {
            timeout = setTimeout(() => {
                setIsExperimentClientTimedOut(true);
                if (window.isNativeApp) {
                    logMessage('AmplitudeExperimentProvider timeout', {
                        level: 'warning',
                        extra: { SDK_TIMEOUT_WAIT },
                    });
                }
            }, SDK_TIMEOUT_WAIT);

            initializeExperimentClientOnMount();

            mounted.current = true;
        }
    }, []);

    /**
     * Get the variant for a feature flag.
     * @example
     * const { experimentClientIsReady, getFeatureFlagVariant} = useAmplitudeExperiment();
     * // Check for SDK readiness using experimentClientIsReady before getting variants.
     * const variant = experimentClientIsReady ? getFeatureFlagVariant('my-flag') : undefined;
     * @param {string} flagName
     * @returns {string|undefined}
     */
    const getFeatureFlagVariant = (flagName = '') => {
        const variant = experimentClient?.variant(flagName)?.value;

        if (!variant && !isProduction) {
            // eslint-disable-next-line no-console
            console.warn(`No variant found for ${flagName}. Experiment client is ready: ${experimentClientIsReady}`);
        }

        return variant;
    };

    const value = useMemo(
        () => ({
            experimentClient,
            experimentClientIsReady,
            experimentClientIsTimedOut,
            getFeatureFlagVariant,
        }),
        [experimentClient, experimentClientIsReady, experimentClientIsTimedOut, getFeatureFlagVariant]
    );

    return <AmplitudeExperimentContext.Provider value={value}>{children}</AmplitudeExperimentContext.Provider>;
}

/**
 * Access the Amplitude Experiment SDK and its feature flags in your components
 * @returns {object} experimentClient - The full, initialized experiment client
 * @returns {boolean} experimentClientIsReady - Whether the experiment client is ready and it is safe to query feature flags
 * @returns {boolean} experimentClientIsTimedOut - Whether the experiment client intialized within the allotted SDK_TIMEOUT_WAIT time
 * @returns {Function} getFeatureFlagVariant - Returns the variant for a feature flag
 */
function useAmplitudeExperiment() {
    return useContext(AmplitudeExperimentContext);
}

export { AmplitudeExperimentContext, AmplitudeExperimentProvider, useAmplitudeExperiment, SDK_TIMEOUT_WAIT };
